#dvs {
  .right-side {
    position: absolute;
    top: 87px;
    right: 58px;
    z-index: 2;

    width: 180px;
    min-height: 256px;
    max-height: calc(100% - 170px);
    box-sizing: border-box;
    padding: 16px 0 24px 0;
    border-radius: 13px 13px 84px 84px;

    box-shadow: 2px 2px 6px 0 rgba(54, 54, 54, 0.5);
    background-image: linear-gradient(179deg, #151517 0%, #052334 88%);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    line-height: 1.25;
    letter-spacing: 0.6px;
    text-align: left;
    color: var(--white-color);

    &:after {
      content: "";
      display: block;
      height: 53px;
      min-height: 53px;
    }
    form {
      min-height: 100%;
      max-height: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .right-side-content {
      overflow: hidden;
      height: 100%;
    }
    .right-side-footer {
      position: relative;
      text-align: center;
      .right-side-icon {
        position: absolute;
        bottom: -53px;
        left: 0;
        right: 0;
        text-align: center;
        cursor: pointer;
        &:hover .cls-1 {
          fill: var(--active-color) !important;
        }
      }
    }

    .properties-header {
      text-align: center;
      margin-bottom: 9px;
      .properties-element {
        letter-spacing: 0.7px;
        color: var(--white-color);
        padding: 0 16px;
        .element-icon {
          margin: 8px 0;
          .invalid-icon {
            cursor: pointer;
          }
        }
      }
    }
    .properties-title {
      text-transform: uppercase;
      letter-spacing: 0.7px;
      text-align: center;
      color: var(--white-color);
    }

    .properties-separator {
      border-bottom: solid 1px var(--line-color);
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .properties {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;

      .properties-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 20px;

        .tool {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }

      .pipeline-properties {
        padding: 0 12px;

        .pipeline-properties-item:first-child {
          margin-top: 0;
        }

        .water-consumption {
          margin-top: 6px;
          .remaining {
            color: var(--blue-color);
            margin-top: 3px;
          }
          .total,
          .remaining {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            & > div:first-child {
              width: 50%;
              text-align: right;
            }
            .item-text {
              margin-right: 2px;
              line-height: 0;
            }
          }
        }
        .pipeline-properties-item {
          margin-top: 12px;

          .pipeline-properties-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
          }
        }
        .item-icon {
          margin-right: auto;
          text-align: center;
          min-width: 62px;
          max-width: 62px;
        }
        .small-text {
          font-size: var(--font12);
          white-space: pre-wrap;
          sup {
            vertical-align: top;
            font-size: 75%;
            position: static;
          }
        }
        .item-text-wrap {
          margin-left: auto;
          margin-right: 0;
          margin-top: 8px;
          text-align: right;
          .text-wrap {
            margin-right: 3px;
          }
        }
        .item-text {
          font-size: var(--font16);
          font-weight: bold;
          margin-right: 2px;
        }
      }

      .properties-content {
        box-sizing: border-box;
        border: 2px dotted #c5d75d;
        border-radius: 8px;
        margin: 6px 6px 6px 8px;
        padding: 12px 0px;
      }

      .properties-item {
        padding: 5px 14px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;

        & .properties-item {
          padding-left: 0;
          padding-right: 0;
        }

        .block-title {
          font-size: 0.75rem;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: 0.6px;
          text-align: left;
          color: #f5f5f5;
        }

        input {
          font-size: 0.875rem;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 0.7px;
          text-align: left;
          padding: 2px 0;

          --input-color: var(--color, #c5d75d);
          color: var(--input-color);
          border-bottom: 2px solid var(--input-color);

          opacity: 0.9;
          &:focus {
            opacity: 1;
          }

          &:disabled {
            --input-color: #c3c3c3;
          }
          &.input-uppercase {
            text-transform: uppercase;
          }
        }

        &.with-control {
          .setting-info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            .setting-icon-wrap {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .setting-icon {
              display: inline-flex;
              align-items: center;

              svg {
                --border-color: var(--color);
                --fill-color: #f5f5f5;

                width: 32px;
                height: auto;
              }
            }
            .setting-value {
              flex-grow: 2;
              text-align: center;
              padding: 0 7px 0 14px;

              display: inline-flex;
              align-items: center;

              input {
                text-align: center;
              }

              .unit {
                font-size: 0.75rem;
                padding-left: 7px;
              }
            }
          }

          .setting-title {
            padding-top: 4px;

            font-size: 0.75rem;
            line-height: 1.25;
            letter-spacing: 0.6px;
          }

          .setting-control {
            flex-basis: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .setting-control-item {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            .setting-control-item-separator {
              flex-basis: 8px;
            }
          }
        }

        &.with-input {
          .icon {
            flex: unset;
            padding-right: 12px;

            display: inline-flex;
            align-items: center;
          }

          .icon + .title {
            white-space: pre-line;
          }

          .title {
            font-size: 0.75rem;
            align-self: center;
            white-space: nowrap;
          }

          .setting-value {
            padding: 0 4px;
            align-self: flex-end;

            input {
              font-size: 0.75rem;
              &.integer-input,
              &.number-input {
                text-align: center;
              }
            }
          }

          .unit {
            align-self: flex-end;
          }
        }

        &.with-multiline-input {
          display: block;
          .title {
            font-size: 0.75rem;

            &.inform {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
            }
          }

          .setting-value {
            padding: 0;

            input {
              font-size: 0.75rem;
              &.integer-input,
              &.number-input {
                text-align: center;
              }
            }
          }

          .properties-text-with-input {
            display: inline-flex;
            align-items: baseline;
            padding: 0;

            font-size: 0.75rem;
            input {
              display: inline-flex;
              margin-left: 4px;
              margin-right: 4px;
              text-align: left;
            }
          }
        }

        &.with-dropdown {
          display: block;
          .title {
            font-size: 0.75rem;
            &.inform {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
            }
          }
          .dropdown {
            margin-top: 5px;
            width: 100%;
            font-size: 0.75rem;

            .dropdown-item {
              padding: 8px;
            }
          }
        }

        &.with-multiselect-dropdown {
          display: block;
          .title {
            font-size: 0.75rem;
            &.inform {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
            }
          }
          .multiselect-dropdown {
            margin-top: 5px;
            width: 100%;
            font-size: 0.75rem;

            .multiselect-dropdown-item {
              padding: 8px;
            }
          }
        }

        &.with-button {
          display: block;
          .title {
            font-size: 0.75rem;
          }
          button {
            margin-top: 5px;

            width: 100%;
            height: 32px;
            border-radius: 16px;
            padding: 8px 6px;

            border: solid 0.7px var(--white-color);
            background-color: #151517;

            display: inline-flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            overflow: hidden;

            &:disabled {
              cursor: not-allowed;
              border-color: var(--light-gray-color);
              color: var(--grey-color);
            }
          }

          &.active button {
            background-color: #c5d75d;
            color: #3e4346;
            border-color: #c5d75d;
          }
        }

        &.with-toggle {
          align-items: center;
          .title:first-child {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        &.with-checkbox {
          .title {
            font-size: 0.75rem;
          }

          .with-inline-input {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: baseline;

            input {
              width: 21px;
              margin-right: 4px;
            }
          }
        }
      }

      .tabs-navigation {
        padding: 10px 0;

        .tabs-navigation-buttons {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          z-index: 0;

          button {
            font-size: 0.75rem;
            padding: 4px 14px;
            &.active {
              font-weight: bold;
              background: linear-gradient(to top, #606835, #c5d75d);

              &:after {
                display: block;
                position: absolute;
                bottom: -20px;
                left: 0;
                right: 0;
                content: "";
                height: 20px;
                background: linear-gradient(to top, #151517, #606835);
              }

              &:first-child {
                border-radius: 0 8px 0 0;
              }

              &:last-child {
                border-radius: 8px 0 0 0;
              }

              &:not(:first-child):not(:last-child) {
                border-radius: 8px 8px 0 0;
              }
            }

            &:disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }

            &.active:disabled {
              cursor: not-allowed;
              background: linear-gradient(to top, #575757, #a1a0a0);
              &:after {
                background: linear-gradient(to top, #0a0a0a26, #575757);
              }
            }
          }
        }

        .tabs-navigation-content {
          position: relative;
          z-index: 2;
          padding-top: 8px;
        }
      }

      .properties-footer {
        .properties-title {
          margin: 8px 0;
        }
        .properties-description {
          height: 57px;
          border: solid 1px var(--line-color);
          border-left: none;
          border-right: none;
          background-color: rgba(21, 21, 23, 0.5);
          resize: none;
          width: 100%;
          box-sizing: border-box;
          font-size: 0.75rem;
          line-height: 1.25;
          letter-spacing: 0.6px;
          text-align: left;
          color: var(--white-color);
          padding: 7px 4px;
          &:focus {
            border: solid 1px rgba(197, 215, 93, 0.6);
            border-left: none;
            border-right: none;
          }
          &::-webkit-input-placeholder {
            color: var(--white-colorO4);
          }
          &::-moz-placeholder {
            color: var(--white-colorO4);
          }
          &:-ms-input-placeholder {
            color: var(--white-colorO4);
          }
          &::-ms-input-placeholder {
            color: var(--white-colorO4);
          }
          &::placeholder {
            color: var(--white-colorO4);
          }
        }
      }

      .dvs-progress-bar {
        height: 16px;
        border-radius: 8px;
        border: solid 0.7px var(--white-color);
        background-color: #052334;
        position: relative;
        overflow: hidden;
        padding: 1.6px;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        &:before {
          content: "";
          display: flex;
          position: absolute;
          left: 50%;
          top: 3px;
          bottom: 3px;
          width: 1px;
          background: var(--white-color);
        }
        .dvs-progress {
          background-color: var(--blue-color);
        }
        &.error {
          .dvs-progress {
            background-color: var(--red-color);
          }
        }
      }
    }
  }

  .sensor-assignment {
    position: absolute;
    top: 150px;
    right: 358px;
    z-index: 2;

    max-height: calc(100% - 170px);
    box-sizing: border-box;
    padding: 0 2px 0 0;
    width: 380px;

    .sensor-assignment-wrapper {
      height: 100%;
      margin-right: 30px;

      box-shadow: 2px 2px 6px 0 rgba(54, 54, 54, 0.5);
      background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
      border-radius: 13px 13px 84px 84px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      color: var(--white-color);
    }

    .sensor-assignment-header {
      box-sizing: border-box;
      min-height: 38px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 18px 14px 10px 14px;

      .close-button {
        width: 12px;
        height: 12px;
        display: inline-flex;
      }
    }

    .sensor-assignment-content {
      font-size: 0.75rem;
      line-height: 1.25;
      letter-spacing: 0.6px;
      text-align: left;
      padding: 0 12px 0 14px;

      overflow: auto;
      height: 100%;

      .assignment-block {
        &.default_changed .subtitle {
          color: #c5d75d;
        }
        .title {
          font-weight: bold;
        }
      }

      .assignment-block + .assignment-block {
        border-top: dashed 1px #c3c3c3;
        margin-top: 14px;
        padding-top: 8px;
      }

      .circuits-list {
        margin-top: 8px;

        display: grid;
        grid-template-columns: 16px 5px minmax(120px, 1fr) 5px minmax(
            120px,
            1fr
          );
        align-items: center;
        gap: 6px 8px;

        .item-title {
          font-weight: bold;
          line-height: 1;
          letter-spacing: 0.3px;
          &.default_changed {
            color: #c5d75d;
          }
        }
        .item-color {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: solid 1px #f5f5f5;
        }
        .dropdown {
          width: auto;
        }
      }
    }

    .sensor-assignment-footer {
      display: block;
      padding: 50px 50px 60px 50px;
      button {
        width: 100%;
        height: 32px;
        border-radius: 16px;
        padding: 8px 6px;

        border: solid 0.7px var(--white-color);
        background-color: #151517;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        overflow: hidden;
      }
    }
  }

  .valves-settings {
    position: absolute;
    box-sizing: border-box;
    left: 58px;
    z-index: 4;

    min-height: 310px;

    margin-right: 98px;
    width: calc(100% - 304px);

    .valves-settings-wrapper {
      height: 100%;

      box-shadow: 2px 2px 6px 0 rgba(54, 54, 54, 0.5);
      background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
      border-radius: 13px 13px 84px 84px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: var(--white-color);
    }

    .close-settings-button {
      position: absolute;
      top: 12px;
      right: 12px;
      margin-left: auto;
      width: 16px;
      height: 16px;
      z-index: 6;
    }

    .tabs-navigation {
      min-height: 0;
      .tabs-navigation-buttons {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        position: absolute;
        width: 100%;
        z-index: 5;

        background-image: linear-gradient(to bottom, #151517, #052334);
        border-radius: 8px 8px 0 0;

        button {
          flex: 1;
          padding: 12px;
          font-size: 0.875rem;
          &.active {
            font-weight: bold;
            background: linear-gradient(to top, #606835, #c5d75d);

            &:after {
              display: block;
              position: absolute;
              bottom: -27px;
              left: 0;
              right: 0;
              content: "";
              height: 27px;
              background: linear-gradient(to top, #151517, #606835);
            }

            &:first-child {
              border-radius: 8px 8px 0 0;
            }

            &:last-child {
              border-radius: 8px 8px 0 0;
            }

            &:not(:first-child):not(:last-child) {
              border-radius: 8px 8px 0 0;
            }
          }

          &.active:disabled {
            cursor: not-allowed;
            background: linear-gradient(to top, #575757, #a1a0a0);
            &:after {
              background: linear-gradient(to top, #0a0a0a26, #575757);
            }
          }
        }
      }

      .tabs-navigation-content {
        display: flex;
        flex-direction: column;

        height: 100%;
      }
    }

    .no-data {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .valves-settings-table {
      min-height: 0;
      overflow: auto;
      position: relative;
      margin-top: 64px;
      height: calc(100% - 64px);
    }

    .grid-wrapper {
      position: relative;
      box-sizing: border-box;

      display: inline-grid;
      padding: 0px 16px 16px 8px;
      min-width: 100%;
      row-gap: 1px;

      &::before {
        content: "";
        position: absolute;
        top: 62px;
        left: 8px;
        right: 16px;
        bottom: 16px;
        border: 2px dotted #c5d75d;
        border-radius: 8px;
      }

      &.living-environment {
        grid-template-columns:
          auto
          minmax(120px, auto)
          minmax(180px, auto)
          minmax(120px, auto)
          repeat(2, minmax(180px, auto))
          minmax(90px, 160px);

        &::before {
          top: 48px;
        }
      }

      &.irrigation-settings {
        grid-template-columns:
          repeat(2, auto)
          minmax(120px, 160px)
          repeat(3, minmax(180px, auto))
          minmax(90px, 160px);

        &::before {
          top: 62px;
        }
      }

      .grid-header {
        display: contents;

        & > div {
          padding: 18px 15px;
          top: 0;
          position: sticky;
          text-align: left;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 12px;
          letter-spacing: 0.35px;

          background: linear-gradient(179deg, #13171b 0%, #11191e 100%);
          z-index: 1;
        }
      }

      .grid-body {
        display: contents;
      }

      .grid-row {
        display: contents;

        & > div {
          position: relative;
          padding: 18px 15px;
        }
      }

      .grid-row.data-changed > div {
        background-color: rgba(197, 215, 93, 0.25);
      }

      .grid-row:hover > div {
        background-color: rgba(197, 215, 93, 0.1);
      }

      .grid-header > .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: break-spaces;

        &.centered {
          justify-content: center;
        }

        a {
          margin-left: 10px;
        }
      }

      .grid-row > div {
        display: flex;

        &.centered {
          justify-content: center;
          align-items: center;
        }

        &.valve-assign {
          align-items: center;
        }

        .color-circle {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: solid 1px #f5f5f5;
          margin: 0 16px 0 16px;
          flex-shrink: 0;
          flex-grow: 0;
        }

        .arrow {
          height: 16px;
          margin-right: 16px;
          flex-shrink: 0;
          flex-grow: 0;
        }

        .arrow + .dropdown {
          width: 153px;
          flex-grow: 0;
          flex-shrink: 0;
        }

        .unit-input {
          display: flex;
          justify-content: center;
          align-items: center;

          .input-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
              width: 36px;
              margin-right: 8px;
              font-size: 0.875rem;
              font-weight: bold;
              font-stretch: condensed;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: 0.7px;

              text-align: left;
              padding: 2px 0;

              --input-color: var(--color, #c5d75d);
              color: var(--input-color);
              border-bottom: 2px solid var(--input-color);

              opacity: 0.9;
              &:focus {
                opacity: 1;
              }

              &:disabled {
                --input-color: #c3c3c3;
              }
              &.input-uppercase {
                text-transform: uppercase;
              }
            }

            .unit {
              align-self: flex-end;
            }
          }
        }
      }
    }

    .valves-settings-dialog {
      display: flex;
      flex-direction: row;
      justify-content: center;

      padding: 50px 50px 42px 50px;

      button {
        height: 32px;
        min-width: 147px;
        border-radius: 16px;
        padding: 7px 18px 9px 19px;

        border: solid 0.7px var(--active-color);
        background-color: #151517;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        overflow: hidden;
        white-space: no-wrap;
        &.on-changes-highlight {
          border: solid 2px var(--active-color);
        }

        &.highlight {
          box-shadow: 2px 0px 12px 12px rgba(197, 215, 93, 0.2);
          border: solid 0.7px #c5d75d;
          transition: box-shadow 400ms;
        }
      }

      button:first-child {
        margin-left: 8px;
        margin-right: 5%;
      }
      button:last-child {
        margin-right: 8px;
      }
    }
  }
}

#dvs {
  .checkbox-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;

    .checkbox {
      cursor: pointer;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      min-height: 24px;
      max-height: 24px;
      border-radius: 6px;
      border: solid 1px var(--white-color);
      background-color: #151517;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-top: 1px;
      padding-left: 1px;
      &.checked {
        border: solid 1px var(--active-color);
      }
      &.disabled {
        border: solid 1px var(--grey-color);
        background-color: var(--grey-color);
      }
    }
    label.checkbox {
      margin-right: 10px;
    }
  }
}

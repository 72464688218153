#dvs {
  .multiselect-dropdown {
    width: 153px;
    letter-spacing: 0.7px;
    color: var(--white-color);
    position: relative;
    .multiselect-dropdown-value {
      width: 100%;
      height: 32px;
      border-radius: 16px;
      border: solid 0.7px var(--white-color);
      background-color: #151517;
      padding: 8px 6px 8px 14px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      .multiselect-dropdown-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80%;
        overflow: hidden;
      }
    }
    .multiselect-dropdown-value:disabled {
      cursor: not-allowed;
      border-color: var(--light-gray-color);
      color: var(--grey-color);
      .cls-1 {
        stroke: var(--light-gray-color) !important;
      }
    }
    .multiselect-dropdown-container {
      position: fixed;
      width: 100%;
      border-radius: 16px;
      border: solid 1px var(--active-color);
      background-image: linear-gradient(to bottom, #151517, #052334);
      letter-spacing: 0.35px;
      padding: 10px 3px;
      box-sizing: border-box;
      z-index: 10;
      visibility: hidden;
      overflow: auto;
      max-height: 200px;

      .multiselect-dropdown-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        cursor: pointer;

        padding: 8px;
        box-sizing: border-box;
        text-align: left;
        color: var(--white-color);
        white-space: pre-line;

        &.active {
          background-color: var(--active-color);
          &:hover {
            font-weight: bold;
          }
        }
        &:not(.active):hover {
          font-weight: bold;
        }
        .checkbox {
          width: 24px;
          min-width: 24px;
          max-width: 24px;
          height: 24px;
          min-height: 24px;
          max-height: 24px;

          border-radius: 6px;
          border: solid 1px var(--white-color);
          background-color: #151517;

          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding-top: 1px;
          padding-left: 1px;
          &.checked {
            border: solid 1px var(--active-color);
          }
          &.disabled {
            border: solid 1px var(--grey-color);
            background-color: var(--grey-color);
          }

          label.checkbox {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

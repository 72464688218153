#dvs-modal {
  .window-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    .window-popup-container {
      border-radius: 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      box-sizing: border-box;
      overflow: hidden;
      line-height: 1.3;
      letter-spacing: 0.8px;
      z-index: 1;
    }
    .window-popup-header {
      background-image: linear-gradient(to bottom, #151517, #052334);
      text-align: left;
      color: var(--active-color);
      padding: 15px 21px;
    }
    .window-popup-content {
      padding: 21px 24px 18px 24px;
    }

    .window-popup-footer {
      background-image: linear-gradient(to top, #151517, #052334);
      text-align: left;
      color: var(--active-color);
      padding: 15px 21px;
    }
    .window-popup-close {
      font-size: 26px;
      position: absolute;
      right: 6px;
      top: 4px;
      line-height: unset;
      cursor: pointer;
    }

    .login {
      width: 414px;
      .window-popup-header {
        text-align: center;
      }
      .window-popup-content {
        padding: 33px 24px 43px 24px;

        .login-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .login-text {
            margin-left: 22px;
            font-weight: bold;
            font-size: var(--font16);
            line-height: 21px;
            a {
              color: var(--active-color);
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .calculation {
      width: 414px;
      text-align: center;
      margin-top: -20px;
      .calculation-icon {
        padding: 16px 0;
      }
      .calculation-text {
        font-weight: bold;
        font-size: var(--font16);
      }
    }

    .error {
      .window-popup-header {
        font-size: 1rem;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: 0.8px;
        text-align: center;
        color: #c5d75d;
        text-transform: uppercase;
      }

      .window-popup-content {
        width: 366px;
        min-height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--font16);
        line-height: 1.14;

        .icon {
          width: 106px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon + .text {
          padding-left: 16px;
        }

        .text {
          white-space: pre-line;

          font-size: 1rem;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: 0.48px;
          text-align: left;
          color: #3e4346;

          a {
            color: #c5d75d;
            text-decoration: underline;
          }
        }
      }
    }

    .alert {
      .window-popup-header {
        font-size: 1rem;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: 0.8px;
        text-align: center;
        color: #c5d75d;
        text-transform: uppercase;
      }

      .window-popup-content {
        width: 366px;
        min-height: 90px;
        font-size: var(--font16);
        line-height: 1.14;

        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        .text {
          white-space: pre-line;

          font-size: 1rem;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: 0.48px;
          color: #3e4346;
          text-align: center;

          a {
            color: #c5d75d;
            text-decoration: underline;
          }
        }

        .text + .buttons {
          margin-top: 28px;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          button {
            border-radius: 16px;
            background-color: #151517;
            box-sizing: border-box;
            letter-spacing: 0.35px;
            text-align: center;
            min-width: 147px;
            padding: 7px;
            color: var(--white-color);

            &.ok {
              border: 1px solid var(--active-color);
            }
          }
        }
      }
    }

    .dialog {
      .window-popup-header {
        font-size: 1rem;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: 0.8px;
        text-align: center;
        color: #c5d75d;
        text-transform: uppercase;
      }

      .window-popup-content {
        width: 366px;
        min-height: 90px;
        font-size: var(--font16);
        line-height: 1.14;

        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        .text {
          white-space: pre-line;

          font-size: 1rem;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: 0.48px;
          color: #3e4346;
          text-align: center;

          a {
            color: #c5d75d;
            text-decoration: underline;
          }
        }

        .text + .buttons {
          margin-top: 28px;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          column-gap: 24px;
          align-items: center;
          button {
            border-radius: 16px;
            background-color: #151517;
            box-sizing: border-box;
            letter-spacing: 0.35px;
            text-align: center;
            min-width: 147px;
            padding: 7px;
            color: var(--white-color);

            &.confirm {
              border: 1px solid var(--active-color);

              &:hover {
                background-color: var(--active-color);
              }
            }
          }
        }
      }
    }
  }
}

#dvs-modal:not(:empty) + #dvs-plan {
  filter: blur(2px);
}

#dvs {
  .irrigation-plan {
    position: relative;
    margin: 0;
    flex: auto;
    z-index: 1;
    .draggable {
      cursor: move;
    }

    .plan-container {
      &,
      & > * {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .plan-tools {
      box-shadow: 2px 2px 6px 0 rgba(5, 35, 52, 0.5);
      background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
      position: absolute;
      bottom: 29px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch;
      padding: 8px 9px;
      height: 48px;
      border-radius: 48px;
      box-sizing: border-box;
      letter-spacing: 0.65px;
      color: var(--white-color);
      z-index: 2;

      .tooltip-wrap.top {
        position: static;
        .tooltip-text {
          bottom: calc(100% + 20px);
          max-width: 100%;
          margin-left: -9px;
          .tooltip-arrow {
            left: 27px;
          }
        }
      }

      .tool {
        cursor: pointer;

        &:hover,
        &.active {
          .cls-1 {
            fill: var(--active-color) !important;
          }
          .cls {
            stroke: var(--active-color) !important;
          }
        }
      }

      .tool-separator {
        width: 0;
        border-left: solid 2px #657d48;
        margin: 0 14px;
        display: inline-block;
      }

      .zoom-info {
        display: flex;
        align-items: center;

        .zoom-text-wrapper {
          padding: 0 18px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: stretch;
          width: 90px;
          overflow: hidden;

          &:empty {
            width: 21px;
          }

          .grid-size {
            position: relative;
            max-width: 100%;

            .zoom-text {
              position: relative;
              text-align: center;
            }

            &:after {
              content: "";
              display: block;

              border: solid 2px rgba(197, 215, 93, 0.7);
              border-top: none;
              width: 100%;
              height: 8px;
            }
          }
        }

        .zoom-control + .zoom-control {
          padding-left: 32px;
        }
      }

      .with-toggle {
        display: flex;
        flex-direction: row-reverse;

        .title {
          white-space: nowrap;
          padding-top: 7px;
        }
      }
    }

    .elements {
      position: relative;
      z-index: 2;

      .element {
        .clickable {
          cursor: pointer;
        }

        &.sensor-element {
          text {
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.06em;
            fill: #3e4346;
          }

          .clickable:hover > circle {
            stroke: #000;
          }
        }
      }

      .trench {
        &:hover {
          cursor: pointer;
          fill: #c5d75d;
        }
      }
    }
  }
}

#dvs {
  * {
    -webkit-overflow-scrolling: touch;
    @-moz-document url-prefix() {
      scrollbar-color: var(--scroll-thumb-color) var(--scroll-track-color) !important;
      scrollbar-width: auto;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--scroll-thumb-color);
    }
    &::-webkit-scrollbar-track {
      border-radius: 1px;
      background-color: var(--scroll-track-color);
    }
    &::-webkit-scrollbar-button {
      height: 0;
      width: 0;
      background-color: none;
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
  }
}

#dvs .page-info-wrapper {
  position: relative;
  z-index: 3;

  &.container {
    padding: 0;
    width: 100%;
    .page-info-content {
      position: relative;
      margin: 0 58px;
    }
  }

  .page-info {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #151517, #052334);
    border-radius: 0 0 16px 16px;
    padding: 14px 41px;
    animation: opacity 0.2s;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    font-size: 1rem;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.8px;
    color: #f5f5f5;
    white-space: pre-line;

    .sensor-list-info {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .weather-information-wrap {
        flex-shrink: 0;
        flex-basis: 33%;
        min-width: 300px;

        .date {
          font-size: 1.5rem;
          font-weight: normal;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: 1.2px;
          text-align: left;
          color: #f5f5f5;
        }

        .weather-information {
          margin-top: 16px;

          display: inline-flex;
          align-items: center;
          justify-content: flex-start;

          .weather-item {
            font-size: 2.25rem;
            font-weight: normal;
            font-stretch: condensed;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: 1.8px;
            color: #f5f5f5;

            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;

            .icon {
              display: inline-flex;
              align-items: center;

              flex-grow: 0;
              flex-basis: 66px;
              min-width: 24px;

              svg {
                width: 100%;
                height: auto;
              }
            }

            .icon + .text {
              padding-left: 16px;
            }

            &.moisture {
              color: #00c4ff;
            }
          }

          .separator {
            width: 32px;
            position: relative;

            &:before {
              content: "";
              display: block;
              border: solid 1px #f5f5f5;
              height: 44px;

              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .sensor-info-list {
        &.grid-list {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
          justify-content: space-between;
        }

        max-height: 200px;
        overflow: auto;

        flex-shrink: 4;
        flex-basis: 66%;
        .sensor-info-item {
          align-items: center;
          display: flex;
          justify-content: flex-start;

          strong {
            color: #f5f5f5;
          }

          .icon {
            flex: unset;
            padding-right: 7px;

            display: inline-flex;
            align-items: center;
          }
        }

        .sensor-info-item + .sensor-info-item {
          margin-top: 8px;
        }
      }
    }

    .sensor-properties-info {
      strong,
      b {
        color: #c5d75d;
      }
    }

    .sensor-info {
      margin-bottom: 14px;
      padding: 0 16px 0 0;

      .history-line {
        max-height: 0;
        overflow: hidden;

        transition: max-height 0.4s;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;

        .separator {
          border-top: solid 1px #c3c3c3;
          padding-top: 16px;
          margin-top: 16px;
        }

        .history-line-chart {
          flex: unset;
        }
      }

      .sensor-info-values {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        padding-left: 21px;

        .sensor-name {
          padding-top: 2px;

          font-size: 1.5rem;
          font-weight: normal;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: 1.2px;
          text-align: left;

          text-transform: uppercase;
          flex: 1 1 220px;

          padding-right: 16px;
        }

        .value-info {
          font-size: 2.25rem;
          font-weight: normal;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: 1.8px;
          text-align: left;
          color: #f5f5f5;

          .row {
            &.text {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .icon {
                flex: unset;

                display: inline-flex;
                align-items: center;

                svg {
                  width: 40px;
                  height: auto;
                }
              }

              .icon + .value {
                padding-left: 8px;
              }
            }

            &.info {
              margin-top: 5px;

              font-size: 0.875rem;
              line-height: 1.14;
              letter-spacing: 0.7px;

              text-align: left;
              padding-left: 48px;
              white-space: nowrap;
            }
          }
        }

        .value-info.battery {
          .info {
            text-align: center;
            padding: 0;
          }
        }

        .separator {
          min-width: 24px;
          flex: 1 1 32px;
          max-width: 50px;
          height: 40px;

          position: relative;

          &:before {
            content: "";
            display: block;
            border: solid 1px #f5f5f5;
            height: 44px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .space {
          flex: 1 1 36px;
          max-width: 36px;
          min-width: 16px;
        }

        .status {
          flex: 1 1 220px;

          .text {
            color: var(--color);
          }
        }
      }

      .fertilizer-values {
        .sensor-name {
          flex-grow: 3;
        }
      }

      .show-more-wrap {
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .show-more {
        box-sizing: content-box;

        padding: 6px;
        width: 16px;
        height: 8px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
          transition: transform 0.1s;
        }

        &:hover {
          .cls-1 {
            fill: var(--active-color) !important;
          }
          .cls {
            stroke: var(--active-color) !important;
          }
        }
      }

      &.more .history-line {
        max-height: 900px;
      }

      .edit-button {
        display: inline-flex;
        align-items: center;
        position: absolute;
        top: 14px;
        right: 18px;

        &:hover {
          .cls-1 {
            fill: var(--active-color) !important;
          }
          .cls {
            stroke: var(--active-color) !important;
          }
        }
      }
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import url(./reset.scss);
@import url(./fonts.scss);
@import url(./scroll.scss);

:root {
  --font-text: "Swis721 BT", sans-serif;
  --line-color: rgba(245, 245, 245, 0.5);
  --active-color: #c5d75d;
  --white-color: #f5f5f5;
  --white-color2: #ffffff;
  --white-colorO4: #f5f5f5d4;
  --grey-color: #9ea1a2;
  --dark_grey-color: #3e4346;
  --red-color: #ff7600;
  --blue-color: #00c4ff;
  --light-gray-color: #c3c3c3;
  --scroll-thumb-color: var(--active-color);
  --scroll-track-color: var(--dark_grey-color);

  --font14: 0.875rem;
  --font16: 1rem;
  --font18: 1.125rem;
  --font12: 0.75rem;
}

html {
  font-size: 100% !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#dvs {
  font-family: var(--font-text);
  color: var(--dark_grey-color);
  line-height: 1.14;
  font-stretch: condensed;
  letter-spacing: 0.3px;
  font-size: var(--font14);
  overflow: hidden;

  input {
    background: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: none;
    width: 100%;
    font: inherit;
  }

  button {
    padding: 0;
    background: none;
    border: none;
    font: inherit;
    color: inherit;
  }

  .container {
    max-width: 1500px;
    margin: 0 auto;
    padding-left: 58px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .tooltip-wrap {
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms;

    position: absolute;
    z-index: 5;

    transform: translate(-20px, -8px);

    .tooltip-content {
      .tooltip-text {
        box-sizing: border-box;
        color: var(--dark_grey-color);
        position: relative;
        max-width: 312px;
        width: max-content;
        background: #e6eacb;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px var(--dark_grey-color);
        border-radius: 8px;
        padding: 9px 15px;
        line-height: 1.14;
        font-size: var(--font14);
        letter-spacing: 0.35px;
        text-align: left;
        white-space: pre-wrap;

        b {
          font-weight: bold;
        }
        i {
          font-style: italic;
        }
      }
      .tooltip-arrow {
        position: relative;
        color: var(--dark_grey-color);

        width: 18px;
        height: 18px;

        transform: translateX(11px);

        span {
          position: absolute;
          top: 0;
          left: 50%;
          line-height: 18px;

          transform: translateX(-50%) rotate(180deg);
          font-size: 26px;
        }
      }
    }
    &.left {
      .tooltip-content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        margin-left: 13px;
      }
      .tooltip-text {
        order: 2;
      }
      .tooltip-arrow {
        transform: rotate(-90deg);
        margin-left: 0px;
      }
    }
  }
}

#dvs-plan {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100vh;
  min-width: 700px;

  position: relative;
}

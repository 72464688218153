#dvs {
  .with-toggle > .title {
    flex-shrink: 2;
    white-space: pre-wrap;
    padding: 4px;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.6px;
  }
  .toggle {
    width: 61.5px;
    min-width: 61.5px;
    max-width: 61.5px;
    height: 30px;
    border-radius: 20px;
    border: solid 1px var(--white-color);
    background-color: #151517;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 3px 3px;
    cursor: pointer;
    justify-content: flex-start;
    .toggle-button {
      width: 24px;
      height: 24px;
      background-color: var(--white-color);
      border-radius: 50%;
    }
    &.selected {
      justify-content: flex-end;
      border: solid 1px var(--active-color);
      .toggle-button {
        background-color: var(--active-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      border-color: var(--light-gray-color);
      .toggle-button {
        background-color: var(--grey-color);
      }
    }
  }
}

#dvs {
  header {
    color: var(--dark_grey-color);

    box-shadow: 0 3px 6px 0 rgba(5, 35, 52, 0.2);
    position: relative;
    z-index: 2;

    .container {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      padding: 19px 19px 19px 58px;
    }

    .logo {
      flex-basis: 33%;

      .logo-wrapper {
        align-self: center;
        display: inline-flex;
        justify-content: flex-start;
        align-items: stretch;

        color: inherit;
        text-decoration: none;

        .logo-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: unset;
          margin-left: 9px;

          svg {
            margin-top: 4px;
            height: 13.6px;
          }

          .plan-name {
            font-size: 0.75rem;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            border-top: solid 1px var(--active-color);
            padding-top: 3px;
            padding-bottom: 3.5px;
            letter-spacing: inherit;
            line-height: normal;

            &.edit {
              padding-bottom: 3px;
              border-bottom: solid 0.5px var(--light-gray-color);
            }

            input {
              font-size: inherit;
              font-weight: normal;
              letter-spacing: inherit;
              color: inherit;
              width: 100%;
              padding: 0;
              text-align: left;
              border: none;
            }
            .tool {
              svg {
                margin: 0;
                height: auto;
              }
            }
          }
        }
      }
    }

    .smart-garden {
      flex-basis: 33%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      font-size: 1.5rem;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: 1.2px;
      text-transform: uppercase;

      svg {
        padding-right: 10px;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }

    .header-col {
      padding: 0 22.5px;
    }

    .right-col {
      flex-basis: 33%;

      display: flex;
      align-items: stretch;
      justify-content: flex-end;

      a.header-col {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        color: inherit;
        text-decoration: none;
        &:active {
          color: inherit;
          text-decoration: none;
        }

        .title {
          padding-top: 5px;
          text-align: center;
          letter-spacing: 0.7px;
          font-size: 0.875rem;
          font-style: normal;
        }

        &.to-plan {
          &:before {
            content: "";
            display: block;
            width: 32px;
            height: 32px;
          }

          .title {
            font-weight: bold;
            font-stretch: condensed;
          }

          svg {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, calc(-100% + 48px));
          }
        }

        &.user .title {
          padding-top: 2px;
        }
      }
    }
  }
}
